import React, {Suspense} from "react";
import { Routes, Route, Outlet, NavLink } from 'react-router-dom';
import Footer from "../components/Footer";
import Header from "../components/Header";
import Home from "../pages/Home"
import { Styles } from "../styles/styles";
import Datenschutz from "../pages/Datenschutz";
import Impressum from "../pages/Impressum";



const Router = () => {
  return (

    <Suspense fallback={null}>
      <Styles />
      <Header />
      {/* <NewsLine/> */}
      <Routes>
        <Route 
          path = "*" 
          element = {<p>There's nothing here: 404!</p>}
        />
        <Route 
          index  element = {<Home/>}
        />
        <Route 
          path = "home" 
          element = {<Home/>}
        />      
        <Route 
          path = "datenschutz" 
          element = {<Datenschutz/>}
        />
        <Route 
          path = "impressum" 
          element = {<Impressum/>}
        />

      </Routes>
      <Footer />
      {/* <Button 
        onClick = {changeState}
      >Zeige Impressum und Datenschutz</Button>
      <Appendix state = {state}/> */}
    </Suspense>
  );
};

export default Router;
