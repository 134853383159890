import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { PngIcon } from "../../common/PngIcon";
import Button2 from "../../common/Button2";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Header = ({ t }: any) => {

  const {pathname} = useLocation();

  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };

      return (
        <>
          <CustomNavLinkSmall onClick={() => setVisibility(false)}>
            <HashLink to = "../#motivation">
                <Span>
                  {t("Motivation")}
                </Span>
            </HashLink>
          </CustomNavLinkSmall>
          <CustomNavLinkSmall onClick={() => setVisibility(false)}>
            <HashLink to = "../#ueber-uns">
                <Span>
                  {t("Über Uns")}
                </Span>
            </HashLink>
          </CustomNavLinkSmall>
          {/* <CustomNavLinkSmall onClick={() => setVisibility(false)}>
            <Link to = "./news">
                <Span>
                  {t("Presse")}
                </Span>
            </Link>
          </CustomNavLinkSmall> */}
          
            {/* <CustomNavLinkSmall onClick={() => setVisibility(false)}>
              <Link to = "./flyer">
                <Span>
                  {t("Web-Flyer")}
                </Span>
              </Link>
            </CustomNavLinkSmall> */}
          
          <CustomNavLinkSmall
            style={{ width: "180px" }}
            onClick={() => {}}
          >
            <Span>
              <HashLink to = "../#social-media" onClick={() => setVisibility(false)}>
              <Button2 content={t("Social Media")} onClick = {()=>{}}/>
              </HashLink>
            </Span>
          </CustomNavLinkSmall>
        </>
      );
    } 

  return (
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <PngIcon src="Logo-GöZe.jpg" width="auto" height="64px" />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
